import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material';
import { RobotoTypo, TimesNewTypo } from '../../../utils/Typographies';
import { useTranslation } from 'react-i18next';
import InvestmentConsultation from '../../../assets/ServicesImages/InvestmentConsultationImages/header.png';
import SchoolLogo from '../../../assets/StudyingAboard/stamfordLogo.png';
import { useStyles } from '../../../utils/headerTheme';
import axios from 'axios';
import { useSelector } from 'react-redux';

export default function SchoolHeader({data}) {
  const { t } = useTranslation();
  const classes = useStyles();
console.log('header data ',data)
  return (
    <Box
      sx={{
        position: 'relative',
        // height: '100%',
        width: '100%',
        overflow: 'hidden',
        background: '#B1D0E0',
      }}

    >
      <Box sx={{
        width: '100%',
        display: 'flex',
        flexDirection: { xs: 'column', md: 'column', lg: 'row', xl: 'row', xxl: 'row' },
        justifyContent: 'end',
      }}
      >
        <Box

          sx={{
            background: '#fff',
            borderBottomRightRadius: { md: '0', lg: '50px' },
            padding: { xs: '0', md: '0', lg: '60px', },
            zIndex: { xs: 0, md: 0, lg: 10000 },
            height: { xs: '140px', md: '100px', lg: '100px', xl: '60' },
            // width: { xs: '100%', md: '100%', lg: '15%', xl: '15%' },
            position: { xs: 'relative', md: 'relative', lg: 'absolute', xl: 'absolute' },
            left: '0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              fontSize: '16px'
            }}
          >
            <Box component='img' sx={{width:'200px', mt:{xs:1, sm:2, lg:0}}} src={`${process.env.REACT_APP_FILE_URL}${data?.school_logo}`}/>
            <RobotoTypo sx={{ textAlign: { xs: 'center', sm: 'left' }, letterSpacing: '0.03em', fontWeight: '500', color: '#003B5C', fontSize: { xs: '20px', sm: '25px', md: '25px', lg: '26px' } }}>
              {/* {t('School.schoolHeader', { returnObjects: true }).name1} */}
              {data?.school_name}
            </RobotoTypo>
            {/* <TimesNewTypo sx={{ textAlign: { xs: 'center', sm: 'left' }, letterSpacing: '0.025em', fontWeight: '700', color: '#177D84', fontSize: { xs: '18px', md: '24px', lg: '23px' } }}>
              {t('School.schoolHeader', { returnObjects: true }).name2}
            </TimesNewTypo> */}
          </Box>
        </Box>

        <Box
          sx={{ width: { xs: '100%', md: '100%', lg: '85%', xl: '85%', xxl: '85%' }, maxWidth: { xs: '100%', md: '100%', lg: '85%', xl: '85%', xxl: '85%' }, position: 'relative', zIndex: '1', borderBottomLeftRadius: { xs: '0px', md: '0px', lg: '0px', xl: '50px', xxl: '50px' }, height: 'calc(80vh - 128px)', marginBottom: { md: '0px', lg: '30px' } }}
        >
          <img
            className={classes.headerImage}
            src={`${process.env.REACT_APP_FILE_URL}${data?.school_banner}`}
            alt='InvestmentConsultation'
            style={{
              objectFit: 'cover',
              height: '100%',
              borderBottomLeftRadius: { xs: '0', md: '0', lg: '0', xl: '50px', xxl: '50px' },
              width: '100%',
              position: { xs: 'relative', md: 'relative', lg: 'absolute', xl: 'absolute', xxl: 'absoulte' },
              right: '0',
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          height: '200px',
          display: { xs: 'none', md: 'none', lg: 'block', xl: 'block', xxl: 'block' },
          width: { xs: '15%', sm: "15%", md: '12%', lg: "10%", xl: '10%' },
          position: 'absolute',
          bottom: '0px',
          left:'30px',
          zIndex: '99',
        }}
      >
            <Box component='button' sx={{color:'white', cursor:'pointer', border:'none',display:'flex', justifyContent:'center', alignItems:'center',width:'150px', height:'150px', borderRadius:'50%', backgroundColor:'#003B5C', '&:hover': {backgroundColor:'white',color:'#003B5C'}}}>
                <RobotoTypo sx={{color:'white', textAlign:'center', letterSpacing: '0.03em', fontWeight: '500', color:'inherit', fontSize: { xs: '18px', sm: '20px', md: '20px', lg: '20px' } }}>
                {t('School.schoolHeader', { returnObjects: true }).assesment}
                </RobotoTypo>
            </Box>
      </Box>
    </Box >
  )
}
